const Noodl = require('@noodl/noodl-sdk');
const Carousel = require('react-multi-carousel').default;

function MultiCarouselComponent(props) {
    // Carousel settings can also be defined as props for customizable carousel behavior
    return (
		<Carousel {...props}>
			{props.children}
		</Carousel>
    );
}


const MultiCarouselNode = Noodl.defineReactNode({
    name: 'Carousel Component',
    category: 'UI Elements',
    getReactComponent() {
        return MultiCarouselComponent;
    },
    inputProps: {
        additionalTransfrom: {type: 'number', default: 0, group: 'Carousel Settings'},
        arrows: {type: 'boolean', default: true, group: 'Carousel Settings'},
		autoPlay: {type: 'boolean', default: false, group: 'Carousel Settings'},
        autoPlaySpeed: {type: 'number', default: 3000, group: 'Carousel Settings'},
        centerMode: {type: 'boolean', default: false, group: 'Carousel Settings'},
        dotListClass: {type: 'string', default: "", group: 'Carousel Appearance'},
        draggable: {type: 'boolean', default: true, group: 'Carousel Interaction'},
        focusOnSelect: {type: 'boolean', default: false, group: 'Carousel Settings'},
        infinite: {type: 'boolean', default: false, group: 'Carousel Settings'},
        itemClass: {type: 'string', default: "", group: 'Carousel Appearance'},
        keyBoardControl: {type: 'boolean', default: true, group: 'Carousel Interaction'},
        minimumTouchDrag: {type: 'number', default: 80, group: 'Carousel Interaction'},
        pauseOnHover: {type: 'boolean', default: true, group: 'Carousel Settings'},
        renderArrowsWhenDisabled: {type: 'boolean', default: false, group: 'Carousel Appearance'},
        renderButtonGroupOutside: {type: 'boolean', default: false, group: 'Carousel Appearance'},
        renderDotsOutside: {type: 'boolean', default: false, group: 'Carousel Appearance'},
		responsive: {type: 'object', default: {}, group: 'Carousel Settings'},
        rewind: {type: 'boolean', default: false, group: 'Carousel Settings'},
        rewindWithAnimation: {type: 'boolean', default: false, group: 'Carousel Settings'},
        rtl: {type: 'boolean', default: false, group: 'Carousel Settings'},
        shouldResetAutoplay: {type: 'boolean', default: false, group: 'Carousel Settings'},
        showDots: {type: 'boolean', default: false, group: 'Carousel Appearance'},
        sliderClass: {type: 'string', default: "", group: 'Carousel Appearance'},
        slidesToSlide: {type: 'number', default: 1, group: 'Carousel Settings'},
        swipeable: {type: 'boolean', default: true, group: 'Carousel Interaction'},
    },
    outputProps: {
        //Connect all desired output props or signals here
    }
})



Noodl.defineModule({
    reactNodes: [
        MultiCarouselNode
    ],
    nodes:[
    ],
    setup() {
        //this is called once on startup
    }
});
